// assets
"use client";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

import WebbDividerSmall from "../webx/webb-divider-sm";
import { NumberFormat } from "../../services/srvc-utilities";
import { TransferFundsCreate } from "../../services/algorand-summit/trxn-funds-transfer-create-x";
import { TransfersAssetsClaim } from "../../services/algorand-summit/summit-trxn-asset-claim";
import { TransfersAssetsCreate } from "../../services/algorand-summit/summit-trxn-asset-transfer";
import { AccountsMinterInit } from "../../services/algorand-summit/minter-account-init";
import { TransfersFundsStatus } from "../../services/algorand-summit/trxn-funds-transfer-status-x";
import { CheckUnitClaimStatus } from "../../services/algorand-summit/check-claim-status";
import { XDCTokenBurn, XDCTokenMint, XDCTransferUnits } from "../../services/srvc-aws-api";
import { GetXDCWalletAccount } from "../../services/srvc-accounts-realm";

export default function AssetsList(props) {
  const {
    handleBack,
    handleNext,
    stepCount,
    totalCarbon,
    setCurrentStep,
    setPaymentSuccesfull,
    setPaymentFailed,
    setStart,
  } = props;

  const intervalidRef = useRef(null);

  // const asset = true;
  const [asset, setAsset] = useState();
  const [user, setUser] = useState(null);

  const [count, setCount] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const [amount, setAmount] = useState(1);

  const [memo, setMemo] = useState("");
  const [search, setSearch] = useState(props.search || "");
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [id, setId] = useState(0);

  const [currency, setCurrency] = useState("INR");
  const [waiting, setWaiting] = useState(false);
  const [trxnid, setTrxnId] = useState("");
  const [response, setResponse] = useState(false);
  const [intervalid, setIntervalId] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [paymentlink, setPaymentLink] = useState('');
  const [unitList, setUnitList] = useState([]);
  const [executionStart, setExecutionStart] = useState();
  const [paymentFlowStart, setPaymentFlowStart] = useState()
  const [done, setDone] = useState()
  const [refresh, setRefresh] = useState(false)
  

  var units = require("../../data.static/data-asset-units-main.json");
 

  useEffect(() => {
    const asset = JSON.parse(localStorage.getItem("indx"));
    // console.log("asset", asset);
    setUser(asset);
    setAsset(asset);
   
  }, [selectedUnit]);

  

  useEffect(()=>{
    
  },[props])

  useEffect(() => {
    var activelist = units.data.filter((x) => x.actv)
    setUnitList(activelist);
    setCount(totalCarbon);
    setSelectedUnit(activelist[0])
    setId(activelist[0]?._id);

    var amnt = ((totalCarbon || 1) * parseFloat(activelist[0]?.rate[0]?.nmbr / 1000000 || 1)+10)
   
    if(currency=="INR")
    { setAmount(parseFloat(Math.trunc(amnt))) }

    if(currency=="USD")
    { setAmount(parseFloat(Math.ceil(amnt)/84).toFixed(2)) }

  }, [asset, refresh, props]);

  const handleClick = async (item) => {
    // router.push(`/units/${item}`)
    setCount(totalCarbon);

    setSelectedUnit(item);

    setId(item._id);
  };


  
  // if (loading){ return ( <> Please Wait... </> ) }
  // if (!data || data.length === 0) { return ( <> No Data... </> ) }
  useEffect(() => {

    var amnt = ((count || 1) * parseFloat(selectedUnit?.rate[0]?.nmbr / 1000000 || 1)+10)
    if(currency=="INR")
    { setAmount(parseFloat(Math.trunc(amnt))) }

    if(currency=="USD")
    { setAmount(parseFloat(Math.ceil(amnt)/84).toFixed(2)) }
    
  }, [id, selectedUnit, currency, refresh]);

  

  useEffect(()=>{

    if(done)
    {
    const fetch = async()=>{

      var datx = {user: asset?.item || ''}
      const xdcwallet = await GetXDCWalletAccount({data: datx})
      var tx = await CreateTransferTransactions()
      var datx = {
          "chain": "50",
          "networkuri": "https://erpc.xinfin.network", ///"https://rpc.apothem.network",
          "contract": "0xdD7833Bc4E76B874f24787AB5a183311a35dac90",
          "to": xdcwallet?.data?.account?.number,
          "amount": (props.totalCarbon).toString()
      }
      var result = await XDCTokenMint({ data: datx });
      // console.log("mint", result.data)
      if(result.stat)
      {
        setExecutionStart(false);
        setCurrentStep(5);
        setPaymentSuccesfull(true);
        datx = {
          "chain": "50",
          "networkuri":"https://erpc.xinfin.network", //"https://rpc.apothem.network", //
          "contract": "0xdD7833Bc4E76B874f24787AB5a183311a35dac90",
          "account": xdcwallet?.data?.account?.number,
          "amount": (props.totalCarbon).toString()
         }
        result = await XDCTokenBurn({data:datx})
        // console.log("burn", result.data)
        props.setLink(`https://xdcscan.io/address/${xdcwallet?.data.account.number}#tokentxns`)
        // props.setLink(result.data.transaction_url);

      }
       //

    }
    fetch()
    }

  },[done])



  const handleBuy = async () => {

    setLoader(true);
    setSubmit(true);
    setPaymentFlowStart(true)
    setWaiting(true)
    setMemo("Please wait...")
    // console.log(amount)
    const datx = {
      credit: { name: asset.name, mail: asset.mail, item: asset.item },
      debit: { name: asset.name, mail: asset.mail, item: asset.item },
      asset: { name: "", memo: "" },
      sale: { number: amount, ticker: currency },
      rate: { number: selectedUnit?.rate?.number, ticker: currency },
      link: {
        success: "https://terano.io/success",
        failed: "https://terano.io/failed",
      },
      media: {},
      meta: {name:'fund transfer',memo: "piwot-paniit-world-of-technology-conference-mumbai-2025"},
      currency:currency,
      env: process.env.REACT_APP_ENVIRONMENT
    };
    //
    const result = await TransferFundsCreate(datx);
    setTrxnId(result?.data?.item || "");
   // console.log(result.data)
    //Start the interval
    intervalidRef.current = setInterval(() => PullTransactionStatus(result?.data?.item, intervalidRef),5000);
 
    if (result.stat) {
      if (currency == "INR") {
        handleRazorPayPaymentGateway(result.data.item);
      } else {
        setPaymentLink(result?.data?.link || '')
        window.open(result.data.link);
      }
    } else {
      setMemo("Server Error")
    }
  };

  // console.log(paymentlink)

  const PullTransactionStatus = async (trxn, intervalidRef) => {
    const datx = { item: trxn };
    const result = await TransfersFundsStatus({ data: datx });

    if (result.stat) {
     
      if (result.data.stat === 6) {
        setWaiting(false);
        setDone(true)
        setResponse(true);
        setExecutionStart(true);
        // setStart(true);
       
        // Stop the interval
        if (intervalidRef.current) {
          clearInterval(intervalidRef.current);
          intervalidRef.current = null; // Reset the reference
        }
      }
      if (result.data.stat === 4 || result.data.stat === 8) {
        setWaiting(false);
        setResponse(false);
        setDone(false)
        

        if (intervalidRef.current) {
          clearInterval(intervalidRef.current);
          intervalidRef.current = null; // Reset the reference
        }
      }
    }
  };

  const handleRazorPayPaymentGateway = async (trxn) => {
    setTrxnId(trxn); 
     console.log(amount)
    const orderCreate = await axios.post(
      "https://ap-south-1.aws.data.mongodb-api.com/app/terano-transfers-razorpay-gqknlhs/endpoint/order/create",
      {
        data: { amount: amount, env: process.env.REACT_APP_ENVIRONMENT },
      }
    );

    const dataSave = await axios.post(
      "https://ap-south-1.aws.data.mongodb-api.com/app/terano-transfers-razorpay-gqknlhs/endpoint/order/save",
      {
        data: { ...orderCreate.data, stat: 0, trxn: trxn },
      }
    );

    const orderTx = orderCreate?.data?._rejectionHandler0;
    setOrderId(orderTx.id);

    // console.log(amount)

    const options = {
      key: process.env.REACT_APP_ENVIRONMENT == "test"? process.env.REACT_APP_RAZOR_PAY_TEST_KEY: process.env.REACT_APP_RAZOR_PAY_LIVE_KEY ,  //"rzp_test_ljnNNFnBUcpoMu": "rzp_live_AYtHZFKKgNJxIm",
      amount: parseFloat(amount * 100),
      currency: "INR",
      name: "Terano",
      description: `account credit`,
      image: "/apple-touch-icon.png",
      order_id: orderTx.id,
      handler: (res) => {
        paymentVerificationSave(res);
        return res;
      },
      prefill: {
        name: "Terano",
        email: asset.mail,
        contact: "",
      },
      notes: {
        address: "https://terano.io",
      },
      theme: {
        color: "#33785d",
      },
    };

    const razor = new window.Razorpay(options);
    razor.open();

  };

  // Cleanup the interval on unmount
  useEffect(() => {
    return () => {
      if (intervalidRef.current) {
        // console.log("Cleaning up interval on unmount.");
        clearInterval(intervalidRef.current);
      }
    };
  }, []);

  const paymentVerificationSave = async (value) => {
    const res = await axios.post(
      "https://ap-south-1.aws.data.mongodb-api.com/app/terano-transfers-razorpay-gqknlhs/endpoint/payment/verify",
      { data: value, env: process.env.REACT_APP_ENVIRONMENT }
    );

    if (res.status == 200) {
      window.scrollTo(0, 0);
      // setPaymentSuccesfull(true);
    }
    if (res.status != 200) {
      window.scrollTo(0, 0);
      setPaymentFailed(true);
    }
  };

  const CreateTransferTransactions = async () => {
    console.log(selectedUnit)
    var creator = selectedUnit?.team?.find((x) => x.sort == "creator");
    console.log(creator)
    const datx = {
      cred: {
        name: asset.name,
        mail: asset.mail,
        item: asset.item,
        role: "user",
      },
      debt: { name: creator?.name, mail: creator?.mail, item: creator?.item },
      sale: { number: (props.totalCarbon * 1000000).toString() },
      creator: creator?.item,
      asset: selectedUnit?.assx?.item,
      unit: selectedUnit?.item,
      stat: 6,
      meta: { name: "asset transfer", memo: "piwot-paniit-world-of-technology-conference-mumbai-2025" },
    };
  
    const result = await TransfersAssetsCreate({ data: datx, srvc: "" });
   
    if (result.stat) {
      
      return result;
    } else return { stat: false };
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };



{/* wait for razorpay payment confirmation */}
if(paymentFlowStart && waiting){
    return(
      <div className={paymentFlowStart && waiting ? "" : "d-none"}>
        <div className="text-center">
          <img className="" src="https://i.gifer.com/1LBN.gif" style={{width:'120px', height:'120px'}}/>
          <p className="text-small text-center">Please wait...</p>
          <div className={currency=="USD" ? '':'d-none'}>
          <p className={ paymentlink ? "text-small text-center":'d-none'} >In case payment window did not open, <span className="text-primary cursor" onClick={()=> window.open(paymentlink)}>click here to pay</span></p>
          </div>
          <p className="text-small text-center">We are confirming your payment, do not close window.</p>
        </div>
      </div>
    )
  }


{/* Payment successfully recieved */}
if(paymentFlowStart && !waiting && !response && done){
  return(
  
   <div className="text-center">
     <img className="" src="https://www.architecturaldigest.in/wp-content/themes/cntraveller/images/check-circle.gif" style={{width:'120px', height:'120px'}}/>
     <p className="text-small text-center">Payment successfully recieved.</p>
   </div>
  
  )
}

{/* Payment Failed  */}
if(paymentFlowStart && !waiting && !response && !done)
{
  return(
      <div className="text-center">
          <img className="" src="https://assets-v2.lottiefiles.com/a/b5641ed8-1152-11ee-ada0-8f4e8e17569e/AVXn9ghicT.gif" style={{width:'120px', height:'120px'}}/>

          <p className="text-small text-center">Payment Failed</p>
      </div>
  )
}

if(executionStart && response && done){
  return(
      <div className="text-center">
          <i
            className="bi bi-suit-club-fill text-success"
            style={{ fontSize: "3rem" }}
          ></i>
          <p className="text-small text-center">Please wait...</p>
          <p className="text-small text-center">minting your Token On blockchain</p>
          <p className="text-small text-center">{memo}</p>
        </div>
  )
}


  return (
    <>
      <div className="d-flex justify-content-between d-none">
        <h3 className="text-lead">SOLAR</h3>
        <p>Showing: {memo}</p>
      </div>
      <WebbDividerSmall />


      {/* web */}
      <div
        className={
         submit ? "d-none" : "row row-cols-1 row-cols-md-1 g-3"
        }
      >
        {unitList &&
          unitList.map((item, x) =>
            item.actv ? (
              <div
                className={`col m-0 mb-3 ${id === item._id ? "" : ""}`}
                key={x}
              >
                <div
                  className={`h-100 rounded border-none p-0 m-0 cursor box border-bottom ${
                    id === item._id ? "bg-light border" : ""
                  }`}
                  onClick={() => handleClick(item)}
                >
                  <div className="media-standard">
                    <img
                      src={item?.medx?.link || ""}
                      fill={true}
                      className="img-fluid w-100 rounded"
                      alt={item?.meta?.memo || "******"}
                    />
                    <div className="btn back-color-wite text-dark text-mini text-bold rounded">
                      {item?.meta?.sort || "******"}
                    </div>
                  </div>

                  <div className="card-body p-0 mt-3 mx-2">
                    <h4 className="text-bold text-normal text- text-color-main d-none d-md-block">
                      {item?.meta?.name || ""}
                    </h4>
                    {/* <p className="text-small text-bold mb-1">
                    {item?.location || "******"}
                  </p> */}
                    <p className="text text-wd m-0 p-0">
                      {item?.meta?.memo || ""}
                    </p>
                  </div>

                  <WebbDividerSmall />
                  <div className="card-footer border-none back-color-none m-0 p-0 mx-2">
                    {/* <p className="m-0">
                    <span className="text-small">{"Required Carbon: "}</span>
                    <span className="text-small">
                      {props.totalCarbon || "0000"}
                    </span>
                  </p>
                  <p className="m-0">
                    <span className="text-small">{"Rate: "}</span>
                    <span className="text-small">
                      {(parseFloat(item?.rate[0]?.nmbr) / 1000000).toFixed(2)}
                    </span>
                  </p> */}
                    <p className="text-small mb-3">
                      {"ID: "}
                      {item?.webx?.nmbr || "******"}
                    </p>
                    <p className="text-normal text-bold mb-3">
                      { currency=="INR"? "Total: ₹ " : "Total: $ "}
                      { currency=="INR"? NumberFormat(
                        (parseFloat((item?.rate[0]?.nmbr) / 1000000) *
                          Math.ceil(props.totalCarbon)+10)
                      ):

                      NumberFormat(
                        (parseFloat(((item?.rate[0]?.nmbr) / 1000000) *
                          Math.ceil(props.totalCarbon)+10)/84), "w",2
                      )

                    }
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          )}
      </div>

      <WebbDividerSmall />
      <p className={"text-small m-0"}>{memo}</p>
      <div className={executionStart || submit ? "d-none" : ""}>

        <div>
          <h6>Select Currency Type</h6>
          <label  className="mx-3">
            <input 
              className="mx-1"
              type="radio"
              value="INR"
              checked={currency === 'INR'}
              onChange={handleCurrencyChange}
            />
            INR
          </label>
          <label  className="mx-3 ">
            <input
              className="mx-1"
              type="radio"
              value="USD"
              checked={currency === 'USD'}
              onChange={handleCurrencyChange}
            />
            USD
          </label>
          {/* <p>Selected Currency: {currency}</p> */}
          <WebbDividerSmall />
        </div>
          <WebbDividerSmall />

        <div className="d-flex align-items-center justify-content-between">
          {stepCount !== 0 && (
            <button
              onClick={() => handleBack()}
              className="btn rounded-xx btn-outline-secondary text-small px-4"
              type="submit"
            >
              Back
            </button>
          )}

          <button
            disabled={totalCarbon == 0 || id == 0}
            onClick={()=>{ setRefresh(!refresh); handleBuy()}}
            className="btn rounded-xx btn-success text-small px-4 "
            type="submit"
          >
            Continue
          </button>
        </div>
      </div>
      <WebbDividerSmall />
    </>
  );
}
