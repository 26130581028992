// init
import axios from "axios";


// -----------------

export const XDCTokenMint = async (item) => {
  
  const basx = "https://srvc.tokenize.ee/api/ercbasic/20/v1/token/mint"
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  } 
   const datx = item.data

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
   // console.log(result)
     return { code: result.data.statusCode, stat: result.data.success, data:result.data.data }; 
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const XDCTokenBurn = async (item) => {
  
  const basx = "https://srvc.tokenize.ee/api/ercbasic/20/v1/token/burn"
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  } 
   const datx = item.data

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
   // console.log(result)
     return { code: result.data.statusCode, stat: result.data.success, data:result.data.data }; 
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}















export const XDCTransferUnits = async (item) => {
  
  //const basx = 'https://ap-southeast-1.aws.data.mongodb-api.com/app/carbonize-rand-ixkdi/endpoint/xdc/token/transfer';
  const basx = "https://srvc.tokenize.ee/api/ercbasic/20/v1/token/mint"
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  //const datx = {data:item.data, srvc:""} 
   const datx = item.data

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
   // console.log(result)
     return { code: result.data.statusCode, stat: result.data.success, data:result.data.data }; 
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}